<template>
    <div class="view-root flex-column">
        <div style="display: flex;justify-content: space-between;align-items: center;">
            <p class="common-title">日程组列表</p>
            <p class="btn-common" @click="editSessionGroup(null)">新增日程组</p>
        </div>

        <div class="table-margin-top flex-1-overflow-y">
            <div class="table-header table-margin-bottom">
                <div class="table-index">序号</div>
                <div class="table-name" style="line-height: unset;">名称</div>
                <div class="table-date">日期</div>
                <div class="table-start_time">开始时间</div>
                <div class="table-total_times">总时长</div>
                <div class="table-operation">操作</div>
            </div>
            <div v-for="(sessionGroup, index) in dataList" :key="index" class="table-item table-margin-bottom">
                <div class="table-index">{{ (index + 1) }}</div>
                <div class="table-name" style="font-weight: bold;">{{ sessionGroup.name }} </div>
                <div class="table-date">{{ formatShowDate(sessionGroup.date) }}</div>
                <div class="table-start_time">{{ sessionGroup.start_time }}</div>
                <div class="table-total_times">{{ sessionGroup.duration }}分钟</div>
                <div class="table-operation">
                    <div style="display: flex;">
                        <!-- <p class="list-btn-yellow" style="text-align: left;padding-left: 8px;"
                            @click="gotoGuests(sessionGroup)">嘉宾</p> -->
                        <p class="list-btn-green" style="text-align: left;padding-left: 8px;margin-left: 10px;"
                            @click="doShowSessionListDialog(sessionGroup)">日程</p>
                        <p class="list-btn-blue" style="text-align: left;padding-left: 8px;margin-left: 10px;"
                            @click="editSessionGroup(sessionGroup)">编辑</p>
                        <p class="list-btn-red" style="text-align: left;padding-left: 8px;margin-left: 10px;"
                            @click="doRemoveSessionGroup(sessionGroup)">删除</p>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="showSessionGroupDialog" :title="sessionGroupInfo.id == '' ? '添加日程组' : '编辑日程组'">
            <div>
                <el-form :model="sessionGroupInfo" :rules="rules" ref="sessionGroupForm">
                    <el-form-item label="日程组" prop="name">
                        <el-input v-model="sessionGroupInfo.name" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="日程组日期" prop="date">
                        <el-select
                            v-model="sessionGroupInfo.date"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in dateRange"
                                :key="item"
                                :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="开始时间" prop="start_time">
                        <el-time-picker v-model="sessionGroupInfo.start_time" format="HH:mm" value-format="HH:mm"
                            :default-value="dafaultValue" placeholder="请选择时间"></el-time-picker>
                    </el-form-item>
                    <el-form-item label="日程组时长" prop="duration">
                        <el-input-number v-model="sessionGroupInfo.duration" placeholder="请输入"></el-input-number>（单位分钟）
                    </el-form-item>
                </el-form>
                <div style="text-align: right;">
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="showSessionGroupDialog = false">取 消</el-button>
                        <el-button type="primary" @click="saveSessionGroup()">确 定</el-button>
                    </span>
                </div>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="showSessionListDialog" title="包含日程列表" width="65%">
            <div style="max-height: 500px;overflow-y: auto;min-height: 350px;">
                <el-table :data="selectSessionGroupInfo.sessions">
                    <el-table-column type="index" prop="index" label="序号"></el-table-column>
                    <el-table-column prop="name" label="名称"></el-table-column>
                    <el-table-column prop="type" label="类型"></el-table-column>
                    <el-table-column prop="" label="日期">
                        <template slot-scope="scope">
                            {{ formatShowDate(scope.row.date) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="开始时间">
                        <template slot-scope="scope">
                            {{ scope.row.start_time }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="总时长">
                        <template slot-scope="scope">
                            {{ scope.row.duration }}分钟
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="赞助商">
                        <template slot-scope="scope">
                            {{ scope.row.partner ? scope.row.partner.name : '' }}
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>

    </div>
</template>
<script>
import '../../assets/common/common.css'
import dayjs from 'dayjs'
import { getAllDatesInRange } from '@/utils/date'
import {
    getMeetingInfo,
    getSessionGroups,
    removeSessionGroup,
    updateSessionGroup
} from '../../api/api'
import {
    formatDate
} from '../../utils/date'
export default ({
    name: 'index',
    data() {
        return {
            dafaultValue: undefined,
            meeting_id: this.$route.query.meeting_id,
            dateRange: [], // 开始日期范围
            dataList: [],
            sessionGroupInfo: {
                id: '',
                meeting_id: this.meeting_id,
                name: '',
                date: '',
                start_time: '',
                duration: ''
            },
            rules: {
                name: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                date: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                start_time: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                duration: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ]
            },
            meetingInfo: {},
            showSessionGroupDialog: false,
            showSessionListDialog: false,
            selectSessionGroupInfo: {
                sessions: []
            }
        }
    },
    mounted() {
        this.dafaultValue = new Date()
        this.dafaultValue.setHours(9)
        this.dafaultValue.setMinutes(0)
        this.dafaultValue.setSeconds(0)
        this.fetchData()
        this.requestMeetingInfo()
    },
    methods: {

        gotoGuests(row) {
            this.$router.push({
                path: '/meeting/sessionGroup/guests',
                query: {
                    session_group_id: row._id.$id
                }
            })
        },

        doShowSessionListDialog(row) {
            this.selectSessionGroupInfo = row
            this.showSessionListDialog = true
        },


        requestMeetingInfo() {
            getMeetingInfo(this.meeting_id).then((res) => {
                const { start_date, end_date } = res.data.data
                this.meetingInfo = res.data.data
                this.dateRange = getAllDatesInRange(start_date * 1000, end_date * 1000)
            })
        },


        fetchData() {
            getSessionGroups(this.meeting_id).then((res) => {
                this.dataList = res.data.data
            })
        },
        doRemoveSessionGroup(row) {
            this.$alert('你确定要删除该日程组吗？', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    if (action == 'confirm') {
                        removeSessionGroup(row._id.$id).then((res) => {
                            this.$message.success('删除成功')
                            this.fetchData()
                        })
                    }
                }
            })
        },
        formatShowDate(time) {
            return formatDate(new Date(time * 1000), 'yyyy-MM-dd')
        },
        editSessionGroup(row) {
            if (row) {
                this.sessionGroupInfo = {
                    id: row._id.$id,
                    meeting_id: row.meeting_id,
                    name: row.name,
                    date: dayjs(row.date * 1000).format('YYYY-MM-DD'),
                    start_time: row.start_time,
                    duration: row.duration
                }
            } else {
                this.sessionGroupInfo = {
                    id: '',
                    meeting_id: this.meeting_id,
                    name: '',
                    date: this.dateRange[0],
                    start_time: '',
                    duration: ''
                }
            }
            this.showSessionGroupDialog = true
        },
        saveSessionGroup() {
            this.$refs.sessionGroupForm.validate((val) => {
                if (val) {
                    const formData = {
                        ...this.sessionGroupInfo,
                        date: dayjs(this.sessionGroupInfo.date).valueOf() / 1000
                    }
                    updateSessionGroup(formData).then((res) => {
                        this.$message.success('保存成功')
                        this.fetchData()
                        this.showSessionGroupDialog = false
                    })
                }
            })
        }
    }
})
</script>
<style scoped lang="scss">
.table-index {
    flex: 1;
    text-align: center;
}

.table-name {
    flex: 3;
    padding: 0px 5px;
    box-sizing: border-box;
    line-height: 16px;
}

.table-date {
    flex: 2;
}

.table-start_time {
    flex: 2;
}

.table-total_times {
    flex: 1.5;
}

.table-operation {
    flex: 4.2;
    text-align: center;
}
</style>